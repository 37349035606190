import { ActionReducer, createReducer, MetaReducer, on } from '@ngrx/store';
import { localStorageSync } from 'ngrx-store-localstorage';
import { TableOptions } from '@lib/rs-ngx';
import { rsLocalStorageMergeReducer } from '@rs-shared-lib/3rd-party-libraries/ngrx-store-localstorage/rs-local-storage-merge-reducer';
import { storeWorkOrderFilters, updateWorkOrderOverviewPaginator, updateWorkOrderSearchTerm, updateWorkOrderSorting } from '@app/supplier/work-order/overview/state/overview.actions';
import { produce } from 'immer';
import { WorkOrderParameters } from '@shared/models/supplier/work-order/work-order-parameters';
import { resetState } from '@core/states/core.actions';

export const overviewWorkOrderFeatureKey = 'overview-supplier-work-order';

export const sortableColumns = ['NUMBER', 'LICENSE_PLATE', 'CREATION_DATE'] as const;
export type SortableColumnsType = typeof sortableColumns[number];

export type WorkOrderOverviewState = {
  tableFilters: WorkOrderParameters;
  tableOptions: Omit<TableOptions<SortableColumnsType>, 'resultCount'>;
  searchTerm: string;
}

export const workOrderInitialState = (): WorkOrderOverviewState => ({
  tableOptions: {
    sort: {
      active: 'CREATION_DATE',
      direction: 'asc'
    },
    pageEvent: {
      length: 0,
      pageIndex: 1,
      pageSize: 10
    }
  },
  tableFilters: {
    leasingCompany: null,
    supplier: null,
    vin: null,
    creationDateFrom: null,
    creationDateTo: null,
    status: {
      ACTION_REQUIRED: true,
      TO_SEND: true,
      IN_TREATMENT_BY_LC: false,
      COMPLETED: false,
    },
  },
  searchTerm: '',
});

export const workOrderReducer = createReducer(
  workOrderInitialState(),

  on(updateWorkOrderOverviewPaginator, (state, { pageEvent }) => {
    return produce(state, (draft) => {
      draft.tableOptions.pageEvent = { ...pageEvent };
      draft.tableOptions.pageEvent.pageIndex = state.tableOptions.pageEvent.pageSize !== pageEvent.pageSize ? 1 : pageEvent.pageIndex + 1;
    });
  }),

  on(updateWorkOrderSorting, (state, { sort }) => {
    return produce(state, (draft) => {
      draft.tableOptions.sort = sort;
    });
  }),

  on(storeWorkOrderFilters, (state, { tableFilters }) => {
    return produce(state, (draft) => {
      draft.tableFilters = tableFilters;
      draft.tableOptions.pageEvent.pageIndex = 1;
    });
  }),

  on(updateWorkOrderSearchTerm, (state, { searchTerm }) => {
    return produce(state, (draft) => {
      draft.searchTerm = searchTerm;
      draft.tableOptions.pageEvent.pageIndex = 1;
    });
  }),

  on(resetState, (_state) => {
    return workOrderInitialState();
  })
);

function localStorageSyncReducer(reducer: ActionReducer<WorkOrderOverviewState>): ActionReducer<WorkOrderOverviewState> {
  return localStorageSync({
    keys: [
      'tableOptions',
      'tableFilters',
      'searchTerm',
    ],
    storageKeySerializer: key => 'supplier_work_order_overview_' + key,
    rehydrate: true,
    mergeReducer: rsLocalStorageMergeReducer,
  })(reducer);
}

export const workOrderMetaReducers: Array<MetaReducer> = [localStorageSyncReducer];
